.service-section {
    background-size: cover;
    background-position: center;
    color: white;
    padding: 20px;
    border-radius: 8px;
  }
  
  .service-box {
    background-color: rgba(0, 0, 0, 0.5); /* Add a dark transparent background for readability */
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    height: 100%;
  }
  
  .service-icon {
    width: 60px;
    height: 60px;
    border: 2px solid #cc9900; /* Dark yellow border */
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
  }
  
  .service-box h5 {
    margin-top: 15px;
    font-size: 1.2em;
  }
  
  .service-box p {
    font-size: 0.95em;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .service-box {
      padding: 10px;
    }
  
    .service-icon {
      width: 50px;
      height: 50px;
    }
  
    .service-box h5 {
      font-size: 1em;
    }
  
    .service-box p {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 576px) {
    .service-box {
      padding: 8px;
    }
  
    .service-icon {
      width: 45px;
      height: 45px;
    }
  
    .service-box h5 {
      font-size: 0.95em;
    }
  
    .service-box p {
      font-size: 0.85em;
    }
  }
  
  