/* src/Portfolio.css */

.portfolio-container {
    padding: 2rem;
    text-align: center;
  }
  
  .portfolio-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center align items */
  }
  
  .portfolio-item {
    margin: 1rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: calc(25% - 2rem); /* Default width for larger screens */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s; /* Smooth hover effect */
  }
  
  .portfolio-item img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px;
  }
  
  .portfolio-item h3 {
    margin: 0.5rem 0;
  }
  
  .portfolio-item p {
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .portfolio-item {
      width: calc(33.33% - 2rem); /* 3 items per row */
    }
  }
  
  @media (max-width: 800px) {
    .portfolio-item {
      width: calc(50% - 2rem); /* 2 items per row */
    }
  }
  
  @media (max-width: 600px) {
    .portfolio-item {
      width: 100%; /* 1 item per row */
    }
  }
  
  /* Hover effect */
  .portfolio-item:hover {
    transform: scale(1.05); /* Slightly scale up on hover */
  }
  
  
  