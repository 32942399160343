.footer {
  background-color: #343a40; /* Ensure dark background */
  color: #f8f9fa; /* Light text */
  padding: 2rem 0;
  position: relative; /* Set position relative for the overlay */
  overflow: hidden; /* Prevent overflow */
  background-image: url('../assets/images/Footer-bg.png'); /* Add the background image */
  background-size: cover; /* Ensure the image covers the entire footer */
  background-position: center; /* Center the image */
}

.elementor-background-overlay {
  position: absolute; /* Position the overlay absolutely */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1; /* Ensure it is behind content */
}

.footer__logo {
  max-width: 150px; /* Ensure the logo doesn't get too large */
  z-index: 2; /* Ensure logo is above the overlay */
  position: relative; /* Ensure proper stacking context */
}

.footer__heading, .footer__subheading {
  font-size: 1.5rem; /* Ensure the headings have a reasonable size */
  color: #ffcc00; /* Light color for contrast */
  margin-bottom: 1rem; /* Add space below headings */
  font-weight: bold;
  z-index: 2; /* Ensure headings are above the overlay */
  position: relative; /* Ensure proper stacking context */
}

.footer__link {
  color: #f8f9fa; /* Light text color for links */
  text-decoration: none;
}

.footer__link:hover {
  color: #ffcc00; /* Change color on hover for better visibility */
}

.footer__li, .footer__li2 {
  list-style-type: none;
}

.footer__social .footer__link {
  color: #f8f9fa;
}

.footer__social .footer__link:hover {
  color: #ffcc00;
}

/* Ensure responsive padding and spacing */
@media (max-width: 768px) {
  .footer {
      text-align: center; /* Center text on smaller screens */
  }
  .footer__heading, .footer__subheading {
      font-size: 1.3rem; /* Slightly smaller font size for smaller screens */
  }
}


  





  
  
  
  