@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

/* Background for the entire app */
.app-background {
  background-image: url('./assets/images/bg3-image.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;   /* Prevents the image from repeating */
  image-rendering: crisp-edges;   /* Helps in some cases to prevent blurring */
  height: 100vh; /* Adjust as needed */
  width: 100vw;
}

/* Navbar styles */
.navbar {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  background-color: #000000 !important;
}

.nav-link {
  color: white !important; /* Ensures nav links are white */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.nav-link:hover {
  color: #ffcc00 !important; /* Optional hover color */
}

.nav-link.active {
  color: #ffc107; /* Change the active link color */
  font-weight: bold; /* Make it bold */
}


/* Brand logo styles */
.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  margin-right: 10px; /* Space between logo and text */
  height: 60px; /* Set a fixed height for the logo */
}

.navbar-brand span {
  font-size: 24px; /* Adjust font size for the logo text */
  font-weight: bold; /* Bold text */
  font-family: 'Anton', sans-serif; /* Specific font for logo text */
}

.nav-item {
  background-color: transparent; /* Set background to transparent */
}

.nav-link {
  color: white !important; /* Ensure text color is white */
}

.nav-item:hover .nav-link {
  color: #ffcc00 !important; /* Change text color on hover */
}



/* Button styles */
.btn-warning {
  background-color: #ffcc00; /* Bright yellow for the Call Now button */
  border: none; /* Remove border */
}

.btn-warning:hover {
  background-color: #e6b800; /* Darker yellow on hover */
}

/* Footer styles */
footer {
  background-color: #343a40; /* Match navbar background */
  color: white; /* White text for footer */
  padding: 20px; /* Space around footer content */
  text-align: center; /* Center footer text */
  position: relative; /* Allow positioning if needed */
}

footer a {
  color: #ffcc00; /* Footer links color */
  text-decoration: none; /* Remove underline from links */
}

footer a:hover {
  text-decoration: underline; /* Underline on hover for clarity */
}

/* Additional styles for content pages */
h3, h4 {
  color: #343a40; /* Darker color for headers */
}

.container {
  margin: 20px auto; /* Center container with margin */
}

/* Add some padding for each page */
.page {
  padding: 20px; /* General padding for content */
}



