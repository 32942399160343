/* About Section Styles */
.container2 {
    max-width: 800px; /* Limit width for better readability */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Add padding around the content */
    background-color: #1a1a1a; /* Dark background for the section */
    color: white; /* White text for contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    background-image: url('../assets/images/electricity-background.jpg'); /* Corrected path */
}

h2, h3 {
    font-family: 'Anton', sans-serif; /* Use Anton font for headings */
    text-align: center; /* Center align the headings */
    margin-bottom: 15px; /* Space below the headings */
}

h2 {
    font-size: 36px; /* Size of the main heading */
}

h3 {
    font-size: 28px; /* Size of the subheadings */
}

p {
    font-family: 'Proxima Nova', sans-serif; /* Use Proxima Nova for paragraph */
    font-size: 18px; /* Size of the paragraph text */
    line-height: 1.6; /* Improve line spacing for readability */
    margin-bottom: 20px; /* Space below paragraphs */
}

ul {
    list-style-type: none; /* Remove default bullet points */
    padding-left: 0; /* Remove left padding */
}

li {
    background: rgba(255, 255, 255, 0.1); /* Light background for list items */
    margin: 5px 0; /* Space between list items */
    padding: 10px; /* Add padding inside list items */
    border-radius: 4px; /* Rounded corners for list items */
}

strong {
    color: #FFD700; /* Highlight strong text with a gold color */
}
