/* PrivacyPolicy.css */

.container {
    max-width: 800px; /* Limits the width for better readability */
    margin: 0 auto; /* Centers the content */
    padding: 15px; /* Adds padding */
  }
  
  h1, h2 {
    font-size: 10px;
    margin-bottom: 15px; /* Adds space below headings */
  }
  
  p {
    margin-bottom: 10px; /* Adds space between paragraphs */
    line-height: 1.6; /* Improves readability */
  }
  
  @media (max-width: 576px) {
    h1 {
      font-size: 24px; /* Adjusts the heading size for smaller screens */
    }
  
    h2 {
      font-size: 20px; /* Adjusts subheading size for smaller screens */
    }
  }
  