/* General styles */

/* Top banner */
.top-banner {
    background-color: black;
    color: white;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
  }
  
  .top-banner a {
    color: white;
    text-decoration: none;
    margin: 0 5px;
  }
  
  .top-banner a:hover {
    text-decoration: underline;
  }

.home-container {
    font-family: 'Proxima Nova', Arial, sans-serif; /* Change font to Proxima Nova */
    text-align: center; /* Center text by default */
}


/* Hero Section */
.hero-section {
    background-image: url('../assets/images/bg3-image.jpg'); /* Replace with your hero image */
    height: 80vh; /* Adjust height as needed */
    background-size: cover;
    background-position: center;
    display: flex; /* Use flexbox to center content */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    position: relative; /* Position relative to contain absolute elements */
    padding: 0;
}

/* Overlay */
.hero-overlay {
    width: 50%; /* Width of the overlay */
    height: 50%; /* Height of the overlay */
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Arrange content vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    position: absolute; /* Position it absolutely within the parent */
    top: 50%; /* Move it to the middle of the parent */
    left: 50%; /* Move it to the middle of the parent */
    transform: translate(-50%, -50%); /* Center it using transform */
    z-index: 1; /* Ensure it is above other content */
    color: #f9f9f9; /* Text color */
    
}

.hero-overlay h1 {
    font-family: 'Anton', sans-serif; /* Apply Anton font */
    color: white; /* Optional: Set text color to white if needed */
    text-align: center; /* Center the text */
    font-size: 48px; /* Adjust size as needed */
}


/* About Section */
.about-section {
    padding: 20px;
    background-color: black; /* Black background */
    color: white; /* White text */
}

  

/* Services Section */
.services-section {
    padding: 20px;
}

.service-item {
    transition: transform 0.3s;
}

.service-item:hover {
    transform: translateY(-5px);
}

/* Testimonials Section */
.testimonials-section {
    background-color: #f9f9f9; /* Light background for contrast */
    padding: 40px 20px; /* Add more padding for larger screens */
    display: flex; /* Use flexbox to align items */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text */
}

.testimonial-item {
    background: white; /* White background for individual testimonials */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px; /* Padding inside each testimonial */
    margin: 15px; /* Margin between testimonials */
    max-width: 600px; /* Limit width for better readability */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.testimonial-item:hover {
    transform: translateY(-5px); /* Lift effect on hover */
}

.testimonial-author {
    font-weight: bold; /* Bold for the author's name */
    margin-top: 15px; /* Space above the author's name */
}

.testimonial-text {
    font-style: italic; /* Italic for testimonial text */
    color: #555; /* Slightly darker color for text */
    margin-bottom: 10px; /* Space below the testimonial text */
}

/* Responsive styles for testimonials */
@media (max-width: 768px) {
    .testimonials-section {
        padding: 30px 15px; /* Adjust padding for smaller screens */
    }

    .testimonial-item {
        max-width: 90%; /* Use more width on smaller screens */
        margin: 10px auto; /* Center the testimonials with auto margins */
    }
}


/* Portfolio Section */
.portfolio-section {
    padding: 60px 20px;
    background-color: #fff;
    text-align: center;
}

.portfolio-section h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-family: "Anton", sans-serif;
    text-transform: uppercase;
}

.portfolio-section .portfolio-scroll {
    display: flex;
    overflow-x: auto;
    padding-bottom: 10px;
}

.portfolio-item {
    flex: 0 0 300px;
    margin-right: 20px;
    text-align: center;
}

.portfolio-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
}

.portfolio-item:hover img {
    transform: scale(1.05);
}

.portfolio-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-family: "Anton", sans-serif;
    text-transform: uppercase;
}

.portfolio-item p {
    font-size: 1rem;
    line-height: 1.5;
}

/* Contact Section */
.contact-section {
    padding: 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .hero-section {
        height: 40vh; /* Decrease height for smaller screens */
    }

    .hero-overlay {
        width: 70%; /* Increase width for smaller screens */
        height: 50%; /* Maintain height for consistency */
    }

    .about-section,
    .services-section,
    .testimonials-section,
    .portfolio-section,
    .contact-section {
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .portfolio-scroll {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: center; /* Center items */
    }

    .portfolio-item {
        min-width: auto; /* Remove min-width for smaller screens */
        width: 90%; /* Use a percentage width for smaller screens */
        margin-bottom: 20px; /* Add space between items */
    }

    .service-item {
        margin-bottom: 20px; /* Add space between services on small screens */
    }
}

@media (max-width: 576px) {
    .hero-section {
        height: 30vh; /* Further decrease height for very small screens */
    }

    .hero-overlay {
        width: 90%; /* Increase width for very small screens */
        height: 60%; /* Maintain height for consistency */
    }

    h1 {
        font-size: 1.5rem; /* Adjust font size for headings */
    }

    h2 {
        font-size: 1.25rem; /* Adjust font size for subheadings */
    }
}

.call-us-section {
    position: relative;
    color: white; /* Change text color for better contrast */
  }
  
  .text-container {
    position: relative; /* Position relative for z-index control */
    z-index: 1; /* Ensure the text container is above the background */
  }
  
  .container.h-100 {
    height: 100%; /* Make container full height */
  }
  
  .row.h-100 {
    height: 100%; /* Make row full height */
  }
  
  .col-md-6.d-flex.align-items-center {
    display: flex; /* Use flexbox for vertical alignment */
    align-items: center; /* Center items vertically */
  }
  
  /* App.css or Button.css */

.btn-gradient {
    background: linear-gradient(90deg, #ffcc00, #d48a12);
    color: #fff; /* White text for contrast */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    transition: all 0.3s ease;
    text-decoration: none; /* Remove underline */
  }
  
  .btn-gradient:hover {
    background: linear-gradient(90deg, #d48a12, #ffcc00); /* Reverse gradient on hover */
    color: #000; /* Optional: Change text color on hover */
    cursor: pointer;
    text-decoration: none; /* Keep underline off */
  }
  

  
  
  
  
  
  



