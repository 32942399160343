.elementor-section {
    padding: 20px;
    background-color: #f9f9f9; /* Optional: Change background color */
}

.elementor-widget-container {
    text-align: center; /* Center text */
    font-family: 'Anton', sans-serif; /* Apply Anton font to all text */
    color: goldenrod; /* Change to your preferred color */
}

.premium-fancy-text-wrapper {
    position: relative; /* Allow absolute positioning of words */
    display: inline-block; /* Prevent line breaks */
}

.word {
    opacity: 0; /* Initially hidden */
    transition: opacity 0.5s ease-in-out; /* Fade effect */
    display: inline-block; /* Prevent stacking */
    font-size: 24px; /* Default font size */
    color: goldenrod; /* Change to your preferred color */
}

.word.visible {
    opacity: 1; /* Make the visible word fully opaque */
}

.premium-prefix-text, 
.premium-suffix-text {
    font-size: 24px; /* Default font size */
    color: #333; /* Change to your preferred color */
}

.typed-cursor {
    font-size: 24px; /* Default cursor size */
    color: #333; /* Change to your preferred color */
    display: inline; /* Cursor displays inline with text */
}

/* Media Queries for Larger Screens */
@media (min-width: 768px) {
    .word, 
    .premium-prefix-text, 
    .premium-suffix-text, 
    .typed-cursor {
        font-size: 32px; /* Increase font size for larger screens */
    }
}


  
  
  